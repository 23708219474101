import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Libraries
import {
  size as _size,
  filter as _filter,
  indexOf as _indexOf,
  map as _map,
  find as _find
} from 'lodash'

// Components
import {
  TitleDefault,
  TitleAlt,
  TitleSubDefault,
  ButtonAltSecondary,
  ButtonMore,
  ContentDefault,
  BoxLast,
} from 'components/Elements'

// Language
import { getLanguage } from 'services/language'

// CSS
import './Posts.scss'

const Posts = ({ categories, limit, showHasMore, text, data }) => {
  const { postsNL, postsEN } = useStaticQuery(graphql`
    {
      postsNL: allWordpressPost(
        sort: {order: DESC, fields: date},
        filter: {
          wpml_current_locale: { eq: "nl_NL" }
          status: { eq: "publish" }
        }
      ) {
        ...postsContent
      }

      postsEN: allWordpressPost(
        sort: {order: DESC, fields: date},
        filter: {
          wpml_current_locale: { eq: "en_US" }
          status: { eq: "publish" }
        }
      ) {
        ...postsContent
      }
    }

    fragment postsContent on wordpress__POSTConnection {
      edges {
        node {
          wordpress_id
          path
          title
          acf {
            intro_text
            categoryId: category_relation
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const language = getLanguage()
  let postsContent = language === 'nl_NL' ? postsNL.edges : postsEN.edges

  const [timesLimit, setTimesLimit] = useState(1)
  limit *= timesLimit

  if (_size(categories) > 0) {
    postsContent = _filter(
      postsContent,
      ({
        node: {
          acf: { categoryId },
        },
      }) => _indexOf(categories, categoryId) !== -1
    )
  }

  const posts = limit
    ? _filter(postsContent, (edge, index) => index < limit)
    : postsContent
  const hasMore = _size(posts) < _size(postsContent)

  return (
    <div className="posts">
      <div className="row">
        {_map(posts, ({ node: { title, wordpress_id: id, path, acf } }) => (
          <Post
            key={id}
            fields={acf}
            title={title}
            path={path}
            readMoreTitle={text.postReadMoreTitle}
            category={
              _find(
                data.categories,
                ({ node: { id } }) => id === acf.categoryId
              ).node.title
            }
          />
        ))}
        {_size(posts) === 0 && (
          <div className="mt-5 mx-4 text-center w-100">
            <TitleAlt className="w-100">
              <div
                dangerouslySetInnerHTML={{ __html: text.filterNoResultsTitle }}
              />
            </TitleAlt>
            <TitleDefault className="mt-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: text.filterNoResultsSubtitle,
                }}
              />
            </TitleDefault>
          </div>
        )}
      </div>

      <div className="text-center mt-4 pb-5">
        {showHasMore && hasMore && (
          <button
            type="button"
            onClick={() => {
              const newTimesLimit = timesLimit + 1

              setTimesLimit(newTimesLimit)
            }}
          >
            <ButtonMore>
              <div
                dangerouslySetInnerHTML={{ __html: text.filterLoadMoreTitle }}
              />
            </ButtonMore>
          </button>
        )}
      </div>
    </div>
  )
}

const Post = ({
  title,
  path,
  category,
  readMoreTitle,
  fields: { intro_text: introText, image },
}) => (
  <div className="col-12 col-lg-6 mb-4">
    <BoxLast className="post">
      <div className="row position-relative">
        <div className="post-image col-12 col-lg-6">
          <BackgroundImage
            className="post-image-source w-100 h-100"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="post-image-overlay d-block d-lg-none" />
        </div>
        <div className="post-content position-relative col-12 col-lg-6 py-3">
          <div className="text-right ml-2 mr-4">
            <TitleSubDefault>
              <div dangerouslySetInnerHTML={{ __html: title }} />
            </TitleSubDefault>
          </div>
          <div className="text-right color-text-striking font-weight-s font-family-secondary mt-3 ml-2 mr-4">
            {category}
          </div>
          <ContentDefault className="mt-3 ml-2 mr-4">
            <div dangerouslySetInnerHTML={{ __html: introText }} />
          </ContentDefault>
          <div className="text-right mt-3 ml-2 mr-4">
            <ButtonAltSecondary to={path}>
              <div dangerouslySetInnerHTML={{ __html: readMoreTitle }} />
            </ButtonAltSecondary>
          </div>
        </div>
      </div>
    </BoxLast>
  </div>
)

export default Posts
